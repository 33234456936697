<template>
  <div
    class="main-content page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="content-margin">
      <banner-section location="shaniv_cart-top"/>
      <div class="cart-content mt-15">
        <h2 class="title fs23 mb-10">
          {{ $t("Open invitations") }}
        </h2>
        <div class="d-flex justify-content-between">
          <div class="w-100">
            <CartTab :activeTab='2'></CartTab>
            <v-data-table
              v-if="tempOrder.length"
              :headers="invitaionsHeaders"
              :items="tempOrder"
              class="cus-table editable-table main-table cart-table"
              item-key="id"
              :expanded.sync="expanded"
            >
              <!-- <template v-slot:[`item.company`]="{ item }">
                <div class="d-flex justify-content-center">
                  <img
                    v-if="item.company == 'sastech'"
                    class="company-logo"
                    src="/new-assets/img/2סאסאטק.png"
                    alt=""
                  />
                  <img
                    v-else-if="item.company == 'shaniv'"
                    class="company-logo"
                    src="/new-assets/img/shaniv-logo.png"
                    alt=""
                  />
                </div>
              </template> -->
              <template v-slot:[`item.amount_of_items`]="{ item }">
                <div class="d-flex justify-content-center weight-700">
                  {{ calcTempOrderQty(item.id) }}
                </div>
              </template>
              <template v-slot:[`item.order_amount`]="{ item }">
                <div class="d-flex justify-content-center weight-700">
                  {{ calcOrderAmount(item.id) | formatPrice }}
                </div>
              </template>
              <template v-slot:[`item.move_to_cart`]="{ item }">
                <div class="d-flex justify-content-center align-items-center">
                  <img src="/assets/img/icons/shopping-cart-light.svg" 
                    alt="" 
                    class="cart-icon pointer" 
                    @click="openTempOrderPopup(item)" 
                  />
                </div>
              </template>
              <template v-slot:[`item.remove`]="{ item }">
                <div class="d-flex justify-content-center align-items-center">
                  <button class="btn-remove" @click="removeOneOrder(item.id)">
                    <img
                      src="/new-assets/icon/trash-red.png"
                      alt=""
                      class="pointer"
                    >
                  </button>
                </div>
              </template>
              <template v-slot:[`item.details`]="{ item }">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="expand-btn plus pointer" @click="expanded = [item]" v-if="!expanded.includes(item)">
                    <img src="/assets/img/icons/plus-light.svg" alt="">
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="expand-btn minus pointer" @click="expanded = []" v-if="expanded.includes(item)">
                    <img src="/assets/img/icons/times-light-orange.svg" alt="">
                  </div>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="manual-card-edit">
                  <CartTable :tableData="item.items" :isEditQty="false" :isTemp="true"></CartTable>
                </td>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <modal
      :width="550"
      :adaptive="true"
      class="tempOrderPopup CEModal modal-scroll-bar"
      name="tempOrderPopup"
    >
      <button class="button modal_close" @click="closeCartPopup()">
        <img src="/assets/img/close.svg" alt />
      </button>
      <div class="mt-10">
        <h2 class="fs18">
          {{ $t("The current set of cart items will be lost. Save your current cart as a temporary order?") }}
        </h2>
        <div class="d-flex mt-15">
          <button class="primary-btn primary-btn_sm" @click="saveCurrentCart()">{{ $t("Yes") }}</button>
          <button class="primary-btn primary-btn_sm bg-red" @click="noSaveCurrentCart()">{{ $t("No") }}</button>
        </div>
      </div>
    </modal>
    <loading
      :active.sync="createOrderLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CartTable from '../../components/cart/CartTable';
import CartTab from '../../components/cart/CartTab';
import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'CartTempOrder',
  mixins: [CartMixin],
  components: {
    BannerSection,
    CartTable,
    CartTab,
  },
  data() {
    return {
      expanded: [],
      invitaionsHeaders: [
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'business_id',
        },
        {
          text: this.$t("Amount of items"),
          align: 'center',
          sortable: false,
          value: 'amount_of_items',
        },
        {
          text: this.$t('Order amount'),
          align: 'center',
          sortable: false,
          value: 'order_amount',
        },
        {
          text: this.$t('Move to cart'),
          align: 'center',
          sortable: false,
          value: 'move_to_cart',
        },
        {
          text: this.$t("Remove"),
          align: 'center',
          sortable: false,
          value: 'remove',
        },
        {
          text: this.$t('Details'),
          align: 'center',
          sortable: false,
          value: 'details',
        }
      ],
      currentTempOrder: {}
    };
  },
  computed: {
    ...mapState({
      createOrderLoading: (state) => state.cartSystem.createOrderLoading,
      tempOrder: (state) => state.cartSystem.tempOrder,
    }),
    ...mapGetters('cartSystem', {
      products: 'products',
      packages: 'packages',
    }),
    cart: function () {
      return this.$store.state.cartSystem.cart;
    },
  },
  methods: {
    ...mapActions('cartSystem', {
      addProductToCart: 'addProductToCart',
      addProductsToCart: 'addProductsToCart',
      deleteProductToCart: 'deleteProductToCart',
      deleteProductToCartByQuantity: 'deleteProductToCartByQuantity',
      initCart: 'initCart',
      createOrder: 'createOrder',
      saveTempOrder: 'saveTempOrder',
      clearCart: 'clearCart',
      getTempOrder: 'getTempOrder',
      removeTempOrder: 'removeTempOrder'
    }),

    /* third tab functionality */
    calcTempOrderQty(id) {
      const tempObject = this.tempOrder.filter((torder) => {
        return torder.id == id; 
      })[0];
      let num = 0;
      for (let i in tempObject.items) {
        tempObject.items[i].packages.forEach(el => {
          let proNum = 0;
          el.products.forEach(pro => {
            proNum += pro.quantity
          })
          num += el.quantity * proNum;
        });
        tempObject.items[i].products.forEach(el => {
          num += el.quantity;
        });
      }
      return num;
    },
    calcOrderAmount(id) {
      const tempObject = this.tempOrder.filter((torder) => {
        return torder.id == id; 
      })[0];
      let price = 0;
      for (let i in tempObject.items) {
        tempObject.items[i].packages.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE;
        });
        tempObject.items[i].products.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE * (el.qtySteps > 0 ? el.qtySteps : 1);;
        });
      }
      return price;
    },
    openTempOrderPopup(item) {
      this.currentTempOrder = JSON.parse(JSON.stringify(item));
      if (Object.keys(this.cart).length != 0) {
        this.$modal.show("tempOrderPopup");
      } else {
        this.addOrderToCart();
        this.$router.push({name: "CartSystem"});
      }
    },
    addOrderToCart() {
      this.currentTempOrder.items.forEach(el => {
        for (let i in el.packages) {
          let tempPackage = el.packages[i];
          let tempPackageQty = el.packages[i].quantity;
          delete tempPackage.quantity;
          this.addProductsToCart({
            product: tempPackage,
            quantity: tempPackageQty,
            company: tempPackage.company,
            type: 'packages'
          });
        }
        for (let j in el.products) {
          let tempProduct = el.products[j];
          let tempProductQty = el.products[j].quantity;
          delete tempProduct.quantity;
          this.addProductsToCart({
            product: tempProduct,
            quantity: tempProductQty,
            company: tempProduct.company,
            type: 'products'
          });
        }
      })  
    },
    closeCartPopup() {
      this.$modal.hide("tempOrderPopup");
    },
    saveCurrentCart() {
      this.saveTempOrder().then(async (res) => {
        if (res == 'success') {
          await this.clearCart();
          this.addOrderToCart();
          this.$router.push({name: "CartSystem"});
        }
      });
      
      this.closeCartPopup();
    },
    async noSaveCurrentCart() {
      await this.clearCart();
      this.addOrderToCart();
      this.closeCartPopup();
      this.$router.push({name: "CartSystem"});
    },
    removeOneOrder(id) {
      this.removeTempOrder({ id: id }).then((res) => {
         if (res == 'success') {
          this.getTempOrder();
        }
      })
    }
  },
 
  async created() {
    this.initCart();
    this.getTempOrder();
  },
};
</script>

<style lang="scss">
  .tempOrderPopup {
    .modal_close {
      position: absolute;
      top: 8px;
      right: 10px;
      img {
        width: 15px;
      }
    }
    .vm--modal {
      padding: 20px;
    }
  }
</style>

<style lang="scss" scoped>
@import '../../assets/scss/_cart';
</style>