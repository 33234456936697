<template>
<div>
  <table class="table w-100 mb-10" v-for="(company, h) in tableData" :key="h">
    <tr class="table-header">
      <th class="text-center fs14 color-purple py-5">{{ $t('Supplier ID') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Products') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Price per unit') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Package Quantity') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Price') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Quantity') }}</th>
      <th class="text-center fs14 color-purple py-5">{{ $t('Sum') }}</th>
    </tr>
    <fragment
    >
      <tr
        v-for="(product, j) in company[isTemp ? 'products' : 'ShanivOrderItemProducts']"
        :key="j"
        :class="{'odd': true, 'failed-order': company.failed, 'payment-required': company.payment_required && !company.paid}"
      >
        <td
          class="text-center vertical-middle company-td border-bottom"
          :rowspan="companyLogoRowspan(company)"
          v-if="j === 0"
        >
          <div>
            <img
              v-if="company.company == 'sastech'"
              class="company-logo"
              src="/new-assets/img/2סאסאטק.png"
              alt=""
            />
            <img
              v-else-if="company.company == 'shaniv'"
              class="company-logo"
              src="/new-assets/img/shaniv-logo.png"
              alt=""
            />
          </div>
        </td>

        <td class="border-bottom">
          <div class="product-content">
            <img
              :src="
                product.image || '/new-assets/img/shaniv-logo.png'
              "
              alt=""
            />

            <div class="product-description">
              <router-link
                :to="{
                  name: 'SastechProduct',
                  params: { company: company.company, id: product.id },
                }"
              >
                <p class="color-purple fs16 weight-700 limited-lines-number lines-number-2" :title="product[currentLang + '_name']">
                  {{ product[currentLang + '_name'] }}
                </p>
              </router-link>
              <p class="fs14 limited-lines-number lines-number-3" :title="product[currentLang + '_description']">
                {{ product[currentLang + '_description'] }}
              </p>
              <p class="fs12 weight-700 mt-5 mb-7">
                {{ $t('Barcode') }}: {{ product.BARCODE || '-' }}
              </p>
            </div>
          </div>
        </td>

        <td class="text-center border-bottom">
          <div class="weight-700 fs16"><i>{{ product.DISCOUNT_VATPRICE | formatPrice }} ₪</i></div>
        </td>

        <td class="text-center border-bottom">
          <div class="weight-700 fs16"><i>{{ getProductPackageQuantity(product.qtySteps) }}</i></div>
        </td>
        <td class="text-center border-bottom">
          <div class="weight-700 fs16"><i>{{ getProductPackageDiscountPrice(product) | formatPrice }} ₪</i></div>
        </td>
        <td class="border-bottom">
          <div class="action-column">
            <HcartNum 
              v-if="isEditQty"
              :num="product.quantity" 
              class="mt-10 mb-5"
              @increase="onIncreaseQuality(product, 'products')"
              @decrease="onDecreaseQuality(product, 'products')">
            </HcartNum>
            <input type="text" v-model="product.quantity" class="qty-num mb-2" readonly v-else>
            <button
              v-if="isRemove"
              class="btn-remove mb-5"
              v-on:click="
                deleteProductToCart({
                  company: company.company,
                  type: 'products',
                  id: product.id,
                })
              "
            >
              <img
                src="/new-assets/icon/trash-red.png"
                alt=""
                class="pointer"
              >
            </button>
          </div>
        </td>
        <td class="text-center border-bottom">
          <div class="weight-700 fs18">{{ calculateProductQuantityPrice(product) | formatPrice }} ₪</div>
        </td>
      </tr>
      <template
        v-for="(product, j) in company[isTemp ? 'packages' : 'ShanivOrderItemPackages']"
      >
        <tr
          :key="j + 'p'"
          class="odd"
          :class="{ 'package-type': true}"
        >
          <td
            class="text-center vertical-middle border-bottom company-td"
            :rowspan="companyLogoRowspan(company)"
            v-if="company[isTemp ? 'products' : 'ShanivOrderItemProducts'].length == 0 && j === 0"
          >
            <div>
              <img
                v-if="company.company == 'sastech'"
                class="company-logo"
                src="/new-assets/img/2סאסאטק.png"
                alt=""
              />
              <img
                v-else-if="company.company == 'shaniv'"
                class="company-logo"
                src="/new-assets/img/shaniv-logo.png"
                alt=""
              />
            </div>
          </td>
          <td class="pointer border-bottom">
            <div class="product-content m-0">
              <img
                :src="
                  product.image || '/new-assets/img/shaniv-logo.png'
                "
                alt=""
              />
              <div class="product-description">
                <router-link
                  :to="{
                    name: 'SastechPackage',
                    params: { company: company.company, id: product.id },
                  }"
                >
                  <p class="color-yellow fs16 weight-700 limited-lines-number lines-number-2" :title="product.name">{{ product.name }}</p>
                </router-link>
                <p class="description fs14"></p>
                <p @click="toggleExpand(j)" class="text-decoration-underline fs16 color-green weight-700">
                  {{ toggleExpandButtonText(j) }}
                </p>
              </div>
            </div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16"><i>{{ product.DISCOUNT_VATPRICE | formatPrice }} ₪</i></div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16"><i>{{ product.qtySteps ? product.qtySteps : 1 }}</i></div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16"><i>{{ getProductPackageDiscountPrice(product) | formatPrice }} ₪</i></div>
          </td>
          <td class="border-bottom">
            <div class="action-column">
              <HcartNum 
                v-if="isEditQty"
                :num="product.quantity" 
                class="mt-10 mb-5"
                @increase="onIncreaseQuality(product, 'packages')"
                @decrease="onDecreaseQuality(product, 'packages')">
              </HcartNum>
              <input type="text" v-model="product.quantity" class="qty-num mb-2" readonly v-else>
              <button
                v-if="isRemove"
                class="btn-remove mb-5"
                v-on:click="
                  deleteProductToCart({
                    company: company.company,
                    type: 'packages',
                    id: product.id,
                  })
                "
              >
                <img
                  src="/new-assets/icon/trash-red.png"
                  alt=""
                  class="pointer"
                >
              </button>
            </div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs18">{{ calculateProductQuantityPrice(product) | formatPrice}} ₪</div>
            <div v-if="calculateProductQuantityDiscount(product) > 0">
              <p class="fs12 color-red8">{{ $t('Full price') }}: {{ calculateProductQuantityPrice({ DISCOUNT_VATPRICE: product.VATPRICE, qtySteps: 1, quantity: product.quantity }) }} ₪</p>
              <p class="fs12 color-green2">{{ $t('Savings') }}: {{ calculateProductQuantityDiscount(product) }} ₪</p>
            </div>
          </td>
        </tr>
        <tr v-for="(subProduct, k) in product[isTemp ? 'products' : 'ShanivOrderItemPackageProducts']" class="expanded" v-show="opened.includes(j)" :key="'' + j + k + 'pp'">
          <td class="border-bottom">
            <div class="product-content m-0">
              <img
                :src="subProduct.image || '/new-assets/img/shaniv-logo.png'"
              />
              <div class="product-description">
                  <p class="color-yellow fs14 weight-700 limited-lines-number lines-number-2" :title="subProduct.ShanivProduct ? subProduct.ShanivProduct[currentLang + '_name'] : subProduct[currentLang + '_name']">{{ subProduct.ShanivProduct ? subProduct.ShanivProduct[currentLang + '_name'] : subProduct[currentLang + '_name'] }}</p>
                  <p class="fs12 limited-lines-number lines-number-3" :title="subProduct.ShanivProduct ? subProduct.ShanivProduct[currentLang + '_description'] : subProduct[currentLang + '_description']">{{ subProduct.ShanivProduct ? subProduct.ShanivProduct[currentLang + '_description'] : subProduct[currentLang + '_description'] }}</p>
                  <p class="fs12 weight-700 mt-5 mb-7">
                    {{ $t('Barcode') }}: {{ subProduct.ShanivProduct && subProduct.ShanivProduct.BARCODE ? subProduct.ShanivProduct.BARCODE : subProduct.BARCODE }}
                  </p>
              </div>
            </div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16">
              <span v-if="subProduct.ShanivProduct || subProduct.DISCOUNT_VATPRICE">
                <i>
                  {{ (subProduct.ShanivProduct ? subProduct.ShanivProduct.DISCOUNT_VATPRICE : subProduct.DISCOUNT_VATPRICE) | formatPrice }} ₪
                </i>
              </span>
              <span class="color-red8 weight-700" v-else>
                  N/A
              </span>
            </div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16"><i>{{ getProductPackageQuantity( subProduct.ShanivProduct ? subProduct.ShanivProduct.qtySteps : subProduct.qtySteps) }}</i></div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs16"><i>{{ getProductPackageDiscountPrice(subProduct.ShanivProduct ? subProduct.ShanivProduct : subProduct) | formatPrice }} ₪</i></div>
          </td>
          <td class="border-bottom">
            <div class="action-column">
              <HcartNum 
                v-if="isEditQty"
                :num="subProduct.quantity" 
                :disabled="true" 
                class="mt-10 mb-5">
              </HcartNum>
              <input type="text" v-model="subProduct.quantity" class="qty-num mb-2" readonly v-else>
            </div>
          </td>
          <td class="text-center border-bottom">
            <div class="weight-700 fs18">{{ calculateSubProductQuantityPrice(subProduct) | formatPrice}} ₪</div>
          </td>
        </tr> 
      </template>
    </fragment>
  </table>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import HcartNum from '../HcartNum';

export default {
  name: 'CartTable',
  props: {
    tableData: Array,
    // products: [Array, Object],
    // packages: [Array, Object],
    isEditQty: {
      type: Boolean,
      default: true
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    isTemp: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HcartNum
  },
  data() {
    return {
      opened: []
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('cartSystem', {
     addProductsToCart: 'addProductsToCart',
     deleteProductToCartByQuantity: 'deleteProductToCartByQuantity',
    }),
    toggleExpand(id) {
    	const index = this.opened.indexOf(id);
      if (index > -1) {
      	this.opened.splice(index, 1);
      } else {
      	this.opened.push(id);
      }
    },
    toggleExpandButtonText(id) {
      return this.opened.indexOf(id) === -1 ? this.$t('More') : this.$t('Hide');
    },
    getProductPackageQuantity(qtySteps) {
      return qtySteps && qtySteps > 0 ? qtySteps : 1;
    },
    getProductPackageDiscountPrice({ qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      DISCOUNT_VATPRICE = DISCOUNT_VATPRICE && DISCOUNT_VATPRICE > 0 ? DISCOUNT_VATPRICE : 0;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    calculateProductQuantityPrice(product) {
      let DISCOUNT_VATPRICE = product.DISCOUNT_VATPRICE;
      let qtySteps = product.qtySteps;
      let quantity = product.quantity;

      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    },
    calculateSubProductQuantityPrice(product) {
      let DISCOUNT_VATPRICE = 0;
      let qtySteps = 1;
      let quantity = 0;
      if (product.ShanivProduct) {
        DISCOUNT_VATPRICE = product.ShanivProduct.DISCOUNT_VATPRICE;
        qtySteps = product.ShanivProduct.qtySteps;
        quantity = product.quantity;
      } else {
        DISCOUNT_VATPRICE = product.DISCOUNT_VATPRICE;
        qtySteps = product.qtySteps;
        quantity = product.quantity;
      }
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    },
    calculateProductQuantityDiscount({ VATPRICE, DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      let discountPrice = this.calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps });
      let price = this.calculateProductQuantityPrice({ DISCOUNT_VATPRICE: VATPRICE, quantity, qtySteps });

      return Math.round(((price - discountPrice) + Number.EPSILON) * 100) / 100;
    },
    onIncreaseQuality(item, type) {
      let quantity = 1;
      this.addProductsToCart({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type
      });
    },
    onDecreaseQuality(item, type) {
      let quantity = 1;
      this.deleteProductToCartByQuantity({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type
      });
    },
    companyLogoRowspan(company) {
      if (this.isTemp) {
        let rowSpan = company.products.length + company.packages.length;
        company.packages.forEach(el => {
          rowSpan += el.products.length;
        });
        return rowSpan;
      } else {
        let rowSpan = company.ShanivOrderItemProducts.length + company.ShanivOrderItemPackages.length;
        company.ShanivOrderItemPackages.forEach(el => {
          rowSpan += el.ShanivOrderItemPackageProducts.length;
        });
        return rowSpan;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    border-collapse: collapse;
  }
  .table-header th {
    border-bottom: 1px solid #9F9F9F;
  }
  tr.failed-order td {
    background-color: lightcoral;
  }
  tr.payment-required td {
    background-color: lightyellow;
  }
  .company-td {
    border-left: 1px solid #9F9F9F;
  }
  .border-bottom {
    border-bottom: 1px solid #9F9F9F;
  }
  .company-logo {
    max-width: 100px;
    width: 100%;
    height: auto;
  }
  .action-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-content {
    display: flex;
    padding: 5px;
    img {
      width: 80px;
      max-height: 100px;
      height: auto;
      object-fit: contain;
      padding: 0 10px;
    }
    .product-description {
      .description {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
  .package-type .product-content img {
    width: 110px;   
  }
  .btn-remove {
    background: #c90000;
    width: 19px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 17px;
    }
  }
  .qty-num {
    background-color: #fff;
    width: 60px;
    text-align: center;
    margin: 0 20px;
    height: 24px;
    font-size: 14px;
    // &:focus-visible {
    //   outline: none;
    // }
  }
  .ltr-type {
    .company-td {
      border-left: none;
      border-right: 1px solid #9F9F9F;
    }
  }
</style>