import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      customerData: (state) => state.shaniv_customer.customerData
    })
  },
  methods: {
    ...mapActions('shaniv_customer', {
      getCustomerData: 'getCustomerData',
    }),
    customerAgentName(customerData) {
      let customerAgentName = '';

      if (customerData
          && typeof customerData === 'object'
          && 'AGENTNAME' in customerData
          && customerData.AGENTNAME) {
        let AGENTNAME = customerData.AGENTNAME.toString();
        customerAgentName = AGENTNAME.replace(/[\d.-]/g, '');
      }

      return customerAgentName;
    },
    customerAgentPhone(customerData) {
      let customerAgentPhone = '';

      if (customerData
          && typeof customerData === 'object'
          && 'AGENTNAME' in customerData
          && customerData.AGENTNAME) {
        let AGENTNAME = customerData.AGENTNAME.toString();
        customerAgentPhone = AGENTNAME.replace(/[^\d]/g, '');
      }

      return customerAgentPhone;
    },
    customerAgentNames() {
      let customerAgentNames = '';

      if (this.customerData) {
        customerAgentNames = Object.values(this.customerData).map(cd => this.customerAgentName(cd)).filter(can => !!can).join(" / ");
      }

      return customerAgentNames;
    }
  },
  created() {
    this.getCustomerData();
  }
}
